import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'
//手机号密码登录
export const login = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Auth/Token?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 账号密码登录
export const loginByAccount = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Auth/LoginByAccount?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 手机号验证码登录
export const codeLogin = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Auth/LoginByCode?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 修改密码 并登录
export const updateLogin = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Auth/ResetPassword?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取页面背景图及logo
export const getConfigData = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataConfig/GetSystemImageUrl?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取页面背景图及logo
export const getRegisterAgreement = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Auth/GetRegisterAgreement`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
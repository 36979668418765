//腾讯地图sdk key
export const TXMapKey = '34PBZ-IGHKP-LESDR-LSOCS-UZ5UJ-R3F7T'

/**
 * 全局变量定义
 */
export let global = {
    "token": '',
    "CanTransfer": '',
    "name": '',
    "FullInfo": "",
    "phone": "",
    "idCard": ""
}